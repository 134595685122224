import { contractSuretyTypeToString, formatToDollar } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { CSVDownloadButton } from "../../../../components/ui/CSVDownloadButton";
import { DataTable } from "../../../../components/ui/DataTable/DataTable";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { SubmittedSuretyAccount } from "../../../../domain/agent/account/types";
import { formatFileName } from "../../../../utils";

type AdminAccountBondsTableProps = {
	account: AdminSuretyAccount;
};

const columns: ColumnDef<SubmittedSuretyAccount["bonds"][number]>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 116,
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		header: "Principal",
		size: 180,
	},
	{
		id: "number",
		accessorKey: "number",
		header: "Bond Number",
		size: 100,
	},
	{
		id: "effectiveDate",
		accessorKey: "effectiveDate",
		header: "Effective Date",
		size: 122,
		accessorFn: ({ effectiveDate }) =>
			effectiveDate ? DateTime.fromISO(effectiveDate).toJSDate() : undefined,
		cell: (props) =>
			DateTime.fromISO(props.row.original.effectiveDate).toLocaleString(DateTime.DATE_MED),
		sortingFn: "datetime",
		enableGlobalFilter: false,
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		cell: (context) => formatToDollar(context.getValue<string>()),
	},
	{
		id: "rate",
		accessorKey: "rate",
		header: "Rate",
		size: 130,
		accessorFn: ({ rate }) => `${rate * 100}%`,
	},
	{
		id: "premium",
		accessorKey: "premium",
		header: "Premium",
		size: 130,
		cell: (context) => formatToDollar(context.getValue<string>(), undefined),
	},
	{
		id: "type",
		accessorKey: "type",
		header: "Type",
		size: 77,
		accessorFn: ({ type }) => contractSuretyTypeToString(type as "bid" | "final"),
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
	},
	{
		id: "obligeeName",
		accessorKey: "obligeeName",
		header: "Obligee Name",
	},
];

export const AdminAccountBondsTable = ({ account }: AdminAccountBondsTableProps) => {
	const navigate = useNavigate();
	const handleBondClick = (bond: SubmittedSuretyAccount["bonds"][number]) => {
		navigate(`/bonds/${bond.id}`);
	};

	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">Bonds</span>
				{account.bonds.length > 0 ? (
					<CSVDownloadButton
						fileName={`${formatFileName(account.displayName)}_bonds_${DateTime.now().toISODate()}`}
						data={account.bonds}
						keys={[
							"status",
							"suretyType",
							"type",
							"number",
							"carrierDisplayName",
							"accountName",
							"principalName",
							"amount",
							"rate",
							"premium",
							"agencyCommission",
							"effectiveDate",
						]}
						className="text-gray-600 hover:text-gray-800"
						loadingStateClassName="text-gray-400"
					>
						<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
					</CSVDownloadButton>
				) : (
					<></>
				)}
			</div>
			{account.bonds.length > 0 ? (
				<div className="w-full h-fit min-h-fit">
					<DataTable
						name="account_bonds"
						columnDefs={columns}
						data={account.bonds}
						tableBodyBorderColor="gray-200"
						tableClassName="shadow-sm max-h-[400px]"
						onRowClick={handleBondClick}
						rowUrl={(row) => `/bonds/${row.id}`}
						expandToRows
					/>
				</div>
			) : (
				<span className="text-[15px] text-gray-600 font-normal italic">No bonds</span>
			)}
		</div>
	);
};
