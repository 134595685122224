import { z } from 'zod';
import { auth0UserRoleSchema, dateTimeSchema, UserType, usStateOrTerritorySchema } from '../../enums.mjs';
import { userId, firstName, lastName, email, agencyId, npn } from '../../types/opaque.mjs';
import { zswitch } from '../../types/utils.mjs';
import '../../types/agency/carrier-agency-relationship.types.mjs';
import '../../types/credit-report/company-credit-report.mjs';
import '../../types/credit-report/individual-credit-report.mjs';
import '../../types/payment/payment-template.mjs';
import '../../types/surety/account/surety-account.types.mjs';
import '../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../types/surety/bond/surety-bond.types.mjs';
import '../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../types/surety/indemnity/indemnity-agreement.mjs';
import '../../types/user/user.types.mjs';
import '../../types/entityRelation/entityRelation.mjs';
import { Get as Get$1 } from '../admin/admin-user.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        const baseUserSchema = z.object({
            id: userId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            roles: auth0UserRoleSchema.array(),
            setupComplete: z.boolean(),
            invitedAt: dateTimeSchema,
        });
        const agentUserSchema = z.discriminatedUnion("setupComplete", [
            z.object({
                id: userId,
                roles: auth0UserRoleSchema.array(),
                type: z.literal(UserType.agent),
                email: email,
                setupComplete: z.literal(false),
                agencyId: agencyId,
                invitedAt: dateTimeSchema,
            }),
            baseUserSchema.extend({
                type: z.literal(UserType.agent),
                agencyId: agencyId,
                npn: npn.nullish(),
                setupComplete: z.literal(true),
                residentLicenseState: usStateOrTerritorySchema.nullish(),
            }),
        ]);
        Response.schema = zswitch((input) => {
            switch (input.type) {
                case UserType.agent:
                    return agentUserSchema;
                case UserType.admin:
                    return Get$1.Response.schema;
            }
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };
