import { NoteTopicId, Relations } from "@inrev/common";
import { useMemo, useState } from "react";
import { Modal } from "../../../../../../components/layout/Modal";
import {
	useUpdateAdminNote,
	useUpdateAdminNoteTopic,
} from "../../../../../../domain/admin/note/api";
import type { AdminNote, AdminNoteTopic } from "../../../../../../domain/admin/note/types";
import { AdminNoteComponent } from "../components/AdminNote";
import { AdminNoteMenu } from "../components/AdminNoteMenu";
import { ArchiveMenuItem } from "../components/AdminNoteMenuItems";
import { AdminNotesArchivedDefaultView } from "./AdminNotesArchivedDefaultView";
import { AdminNotesArchivedTopicView } from "./AdminNotesArchivedTopicView";

export const AdminNotesArchivedSection = ({
	relation,
	defaultArchivedTopic,
	archivedTopics,
}: {
	relation: Relations.Notes;
	defaultArchivedTopic: AdminNoteTopic;
	archivedTopics: AdminNoteTopic[];
}) => {
	const [open, setOpen] = useState(false);
	const [currentTopicId, setCurrentTopicId] = useState<NoteTopicId | undefined>(undefined);
	const { updateAdminNoteTopic } = useUpdateAdminNoteTopic();
	const { updateAdminNote } = useUpdateAdminNote();

	const currentTopic = useMemo(() => {
		if (currentTopicId === undefined) {
			return undefined;
		}
		if (archivedTopics !== undefined) {
			const topic = archivedTopics.find((topic) => topic.id === currentTopicId);
			return topic;
		}
	}, [currentTopicId, archivedTopics]);

	const unarchiveTopic = () => {
		if (currentTopic !== undefined) {
			updateAdminNoteTopic({
				data: {
					isArchived: false,
				},
				relation,
				noteTopicId: currentTopic.id,
			});
		}
	};

	const unarchiveNote = (note: AdminNote) => {
		updateAdminNote({
			data: {
				isArchived: false,
			},
			noteId: note.id,
			topicId: note.topicId,
			relation,
		});
	};

	const setCurrentTopic = (topic?: AdminNoteTopic) => {
		if (!topic) {
			setCurrentTopicId(undefined);
			return;
		}
		setCurrentTopicId(topic.id);
	};

	const currentNotes = useMemo(() => {
		const notes = currentTopic?.notes ?? defaultArchivedTopic?.notes;
		const topic = currentTopic ?? defaultArchivedTopic;
		if (!notes || !topic || !topic) return null;

		return (
			<>
				{notes.map((note, idx) => {
					return (
						<AdminNoteComponent
							key={`archived-note-${idx}`}
							note={note}
							relation={relation}
							topicId={topic.id}
							topics={archivedTopics}
							setCurrentTopic={setCurrentTopic}
							isUpdateAllowed={false}
							noteMenu={
								<AdminNoteMenu>
									<ArchiveMenuItem
										isArchived={note.isArchived}
										archiveNote={() => unarchiveNote(note)}
										disabled={false}
									/>
								</AdminNoteMenu>
							}
						/>
					);
				})}
			</>
		);
	}, [defaultArchivedTopic, currentTopic, archivedTopics]);

	return (
		<div>
			<div
				className="flex flex-row justify-start items-center w-full px-6 my-3 cursor-pointer"
				onClick={() => setOpen(!open)}
			>
				<div className="text-gray-400 text-[12px] hover:text-gray-800">Archived</div>
			</div>
			{open && (
				<Modal onClickOutside={() => setOpen(false)}>
					<div className=" bg-white w-[60vw] max-w-[900px] h-[80vh] rounded-md py-5">
						<div className="text-gray-800 text-[15px] font-medium px-6 pb-5">
							Archived Admin Notes
						</div>
						{currentTopic === undefined && (
							<AdminNotesArchivedDefaultView
								relation={relation}
								defaultArchivedTopic={defaultArchivedTopic}
								archivedTopics={archivedTopics}
								setCurrentTopic={setCurrentTopic}
								notesList={currentNotes}
							/>
						)}
						{currentTopic !== undefined && (
							<AdminNotesArchivedTopicView
								relation={relation}
								archivedTopic={currentTopic}
								onBack={() => setCurrentTopic(undefined)}
								onUnarchive={currentTopic.isArchived ? unarchiveTopic : undefined}
								notesList={currentNotes}
							/>
						)}
					</div>
				</Modal>
			)}
		</div>
	);
};
