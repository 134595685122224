import { Relations } from "@inrev/common";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "../../../../../components/ui/Spinner";
import {
	useDeleteAdminNoteTopic,
	useFetchAdminNoteTopics,
	useUpdateAdminNote,
} from "../../../../../domain/admin/note/api";
import { AdminNote, AdminNoteTopic } from "../../../../../domain/admin/note/types";
import { AdminNotesDefaultView } from "./AdminNotesDefaultView";
import { AdminNotesTopicView } from "./AdminNotesTopicView";
import { AdminNoteComponent } from "./components/AdminNote";
import { AdminNoteMenu } from "./components/AdminNoteMenu";
import { ArchiveMenuItem, PinMenuItem } from "./components/AdminNoteMenuItems";

export const AdminNotesTool = ({ relation }: { relation: Relations.Notes }) => {
	const { deleteAdminNoteTopic } = useDeleteAdminNoteTopic();
	const { updateAdminNote } = useUpdateAdminNote();
	const [searchParams, setSearchParams] = useSearchParams();
	const { defaultTopic, topics, defaultArchivedTopic, archivedTopics, adminNoteTopicsIsLoading } =
		useFetchAdminNoteTopics(relation);

	const currentTopic = useMemo(() => {
		const paramTopicId = searchParams.get("noteTopicId");
		if (paramTopicId === null) {
			return undefined;
		}
		if (topics !== undefined) {
			const topic = topics.find((topic) => topic.id === paramTopicId);
			if (topic === undefined) {
				setSearchParams((prev) => {
					prev.delete("noteTopicId");
					return prev;
				});
				return undefined;
			}
			return topic;
		}
	}, [searchParams, topics]);

	const setCurrentTopic = (topic?: AdminNoteTopic) => {
		if (topic === undefined) {
			setSearchParams((prev) => {
				prev.delete("noteTopicId");
				return prev;
			});
		} else {
			setSearchParams((prev) => {
				prev.set("noteTopicId", topic.id);
				return prev;
			});
		}
	};

	const deleteOrArchiveTopic = () => {
		if (currentTopic !== undefined) {
			deleteAdminNoteTopic({ noteTopicId: currentTopic.id, relation });
			setCurrentTopic(undefined);
		}
	};

	const archiveNote = (note: AdminNote) => {
		updateAdminNote({
			data: {
				isArchived: !note.isArchived,
			},
			noteId: note.id,
			topicId: note.topicId,
			relation,
		});
	};

	const pinNote = (note: AdminNote) => {
		updateAdminNote({
			data: {
				isPinned: !note.isPinned,
			},
			noteId: note.id,
			topicId: note.topicId,
			relation,
		});
	};

	const currentNotes = useMemo(() => {
		const notes = currentTopic?.notes ?? defaultTopic?.notes;
		const topic = currentTopic ?? defaultTopic;
		if (!notes || !topic || !topics) return null;
		return (
			<>
				{notes.map((note, idx) => {
					return (
						<AdminNoteComponent
							key={idx}
							note={note}
							relation={relation}
							topicId={topic.id}
							topics={topics}
							setCurrentTopic={setCurrentTopic}
							isUpdateAllowed={true}
							noteMenu={
								<AdminNoteMenu>
									<PinMenuItem
										isPinned={note.isPinned}
										pinNote={() => pinNote(note)}
										disabled={false}
										key={`pin-${note.id}`}
									/>
									<ArchiveMenuItem
										isArchived={note.isArchived}
										archiveNote={() => archiveNote(note)}
										disabled={false}
										key={`archive-${note.id}`}
									/>
								</AdminNoteMenu>
							}
						/>
					);
				})}
			</>
		);
	}, [defaultTopic, currentTopic, topics]);

	if (defaultTopic === undefined || topics === undefined || adminNoteTopicsIsLoading) {
		return <Spinner />;
	}

	if (currentTopic === undefined) {
		return (
			<AdminNotesDefaultView
				relation={relation}
				defaultTopic={defaultTopic}
				topics={topics}
				defaultArchivedTopic={defaultArchivedTopic}
				archivedTopics={archivedTopics}
				setCurrentTopic={setCurrentTopic}
				notesList={currentNotes}
			/>
		);
	}

	const currentTopicDeletable = currentTopic.notes?.length === 0 && currentTopic.summary === "";

	return (
		<AdminNotesTopicView
			relation={relation}
			topic={currentTopic}
			onBack={() => setCurrentTopic(undefined)}
			onDelete={currentTopicDeletable ? deleteOrArchiveTopic : undefined}
			onArchive={currentTopicDeletable ? undefined : deleteOrArchiveTopic}
			notesList={currentNotes}
		/>
	);
};
