import { contractSuretyTypeToString, formatToDollar } from "@inrev/common";
import { ColumnDef } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { CSVDownloadButton } from "../../../../components/ui/CSVDownloadButton";
import { DataTable } from "../../../../components/ui/DataTable/DataTable";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { BondRequestPreview } from "../../../../domain/agent/request/types";
import { formatFileName } from "../../../../utils";

type AdminAccountRequestsTableProps = {
	account: AdminSuretyAccount;
};

const columns: ColumnDef<BondRequestPreview>[] = [
	{
		id: "status",
		accessorKey: "status",
		header: "Status",
		size: 162,
		filterFn: "equalsString",
		cell: (props) => getStatusTagByStatus(props.row.original.status),
	},
	{
		id: "principalName",
		accessorKey: "principalName",
		header: "Principal",
		size: 180,
	},
	{
		id: "submittedAt",
		accessorKey: "submittedAt",
		header: "Submitted",
		size: 122,
		accessorFn: ({ submittedAt }) =>
			submittedAt ? DateTime.fromISO(submittedAt).toLocaleString(DateTime.DATE_MED) : undefined,
		enableGlobalFilter: false,
		sortingFn: "datetime",
	},
	{
		id: "amount",
		accessorKey: "amount",
		header: "Amount",
		size: 130,
		cell: (context) => formatToDollar(context.getValue<string>(), undefined),
	},
	{
		id: "description",
		accessorKey: "description",
		header: "Description",
		size: 145,
	},
	{
		id: "type",
		accessorKey: "suretySubType",
		header: "Type",
		size: 77,
		accessorFn: ({ suretySubType }) => contractSuretyTypeToString(suretySubType!),
	},
];

export const AdminAccountRequestsTable = ({ account }: AdminAccountRequestsTableProps) => {
	const navigate = useNavigate();
	const handleRequestClick = (request: BondRequestPreview) => {
		navigate(`/requests/${request.id}`);
	};

	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">Requests</span>
				{account.quotes.length > 0 ? (
					<CSVDownloadButton
						fileName={`${formatFileName(account.displayName)}_requests_${DateTime.now().toISODate()}`}
						data={account.quotes}
						keys={[
							"principalName",
							"accountName",
							"amount",
							"description",
							"suretySubType",
							"status",
							"submittedAt",
						]}
						className="text-gray-600 hover:text-gray-800"
						loadingStateClassName="text-gray-400"
					>
						<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
					</CSVDownloadButton>
				) : (
					<></>
				)}
			</div>
			{account.quotes.length > 0 ? (
				<div className="w-full h-fit min-h-fit">
					<DataTable
						name="account_bond_requests"
						columnDefs={columns}
						data={account.quotes}
						tableBodyBorderColor="gray-200"
						tableClassName="shadow-sm max-h-[400px]"
						onRowClick={handleRequestClick}
						rowUrl={(row) => `/requests/${row.id}`}
						expandToRows
					/>
				</div>
			) : (
				<span className="text-[15px] text-gray-600 font-normal italic">No requests</span>
			)}
		</div>
	);
};
